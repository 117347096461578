import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BasicIntersectionObservable extends Vue {
    animationIntersectionObserver: IntersectionObserver | null = null
    enterTimeout: number | undefined

    mounted() {
        this.animationIntersectionObserver = new IntersectionObserver(this.onAnimationIntersectionObserverChange, {
            rootMargin: '-20px'
        })
        this.animationIntersectionObserver.observe(this.$el)
    }

    beforeDestroy() {
        this.animationIntersectionObserver?.disconnect()
    }

    onAnimationIntersectionObserverChange(entries: IntersectionObserverEntry[]) {
        if (entries[0].isIntersecting) {
            // Prevent triggering play while sliding too quickly
            window.clearTimeout(this.enterTimeout)
            this.enterTimeout = window.setTimeout(() => {
                this.onObservableEnter()
            }, 100)
        } else {
            window.clearTimeout(this.enterTimeout)
            this.onObservableLeave()
        }
    }

    onObservableEnter() {
        // override
    }

    onObservableLeave() {
        // override
    }
}
