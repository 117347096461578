








































import { Component } from 'vue-property-decorator'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import MediaContent from '@/components/MediaContent.vue'
import Markdown from '@/components/Markdown.vue'
import ArrowDownNextSlide from '@/components/ArrowDownNextSlide.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import BasicIntersectionObservable from '@/mixins/BasicIntersectionObservable'
import LazyloadIntersectionObservable from '@/mixins/LazyloadIntersectionObservable'
import CheatSheet from '@/components/CheatSheet.vue'

@Component({
    components: {
        ScrollerElement,
        MediaContent,
        Markdown,
        ArrowDownNextSlide,
        CheatSheet
    }
})
export default class SlideLayout extends mixins(Slide, BasicIntersectionObservable, LazyloadIntersectionObservable) {
    $refs!: {
        background: MediaContent
        overTitle: HTMLElement
        title: HTMLElement
        body: Markdown
    }

    get backgroundMedia(): RoadizDocument | undefined {
        return this.walkerItem.backgroundMedia?.[0]
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemLayout
    }

    get classNames() {
        const classNames = [this.$style.root]

        if (this.walkerItem.layout) {
            classNames.push(this.$style['root--' + this.walkerItem.layout])
        } else {
            classNames.push(this.$style['root--fullscreen_text_left'])
        }

        if (this.walkerItem.backgroundAlign) {
            classNames.push(this.$style['root--background-align-' + this.walkerItem.backgroundAlign])
        }

        if (!this.walkerItem.overTitle && !this.walkerItem.title && !this.walkerItem.content) {
            classNames.push(this.$style['root--empty'])
        }

        return classNames
    }

    populateAppear(timeline: GSAPTimeline) {
        const textElements: Array<HTMLElement> = []

        if (this.$refs.overTitle) textElements.push(this.$refs.overTitle)
        if (this.$refs.title) textElements.push(this.$refs.title)
        if (this.$refs.body) textElements.push(this.$refs.body.$el as HTMLElement)

        if (this.$refs.background) {
            timeline.fromTo(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 2.4,
                    ease: 'power2'
                },
                0.5
            )
        }

        if (textElements.length) {
            timeline.fromTo(
                textElements,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.2,
                    ease: 'power3',
                    stagger: 0.1
                },
                0.8
            )
        }
    }

    populateEnterY({ timeline }: ScrollerElementAnimationEvent) {
        const textElements: Array<HTMLElement> = []

        if (this.$refs.overTitle) textElements.push(this.$refs.overTitle)
        if (this.$refs.title) textElements.push(this.$refs.title)
        if (this.$refs.body) textElements.push(this.$refs.body.$el as HTMLElement)

        if (this.$refs.background) {
            timeline.fromTo(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 1,
                    ease: 'none'
                },
                0
            )
        }

        if (textElements.length) {
            timeline.fromTo(
                textElements,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    ease: 'none',
                    stagger: 0.1
                },
                0.5
            )
        }
    }

    /**
     * @override
     */
    onLazyloadObservableEnter() {
        const media = this.$refs.background as MediaContent | undefined
        media?.load()
    }

    /**
     * @override
     */
    onObservableEnter() {
        const media = this.$refs.background as MediaContent | undefined
        if (media) {
            media.play()
        }
    }

    /**
     * @override
     */
    onObservableLeave() {
        const media = this.$refs.background as MediaContent | undefined
        if (media) {
            media.pause()
        }
    }
}
