import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LazyloadIntersectionObservable extends Vue {
    lazyloadObserverIsIntersecting = false

    lazyloadIntersectionObserver!: IntersectionObserver
    lazyloadEnterTimeout!: number

    mounted() {
        const elWidth = this.$el.getBoundingClientRect().width
        const elHeight = this.$el.getBoundingClientRect().height
        const margins = `${elWidth + 50}px ${elHeight + 50}px ${elWidth + 50}px ${elHeight + 50}px`

        this.lazyloadIntersectionObserver = new IntersectionObserver(this.onLazyloadIntersectionObserverChange, {
            rootMargin: margins
        })
        this.lazyloadIntersectionObserver.observe(this.$el)
    }

    beforeDestroy() {
        this.lazyloadIntersectionObserver?.disconnect()
    }

    onLazyloadIntersectionObserverChange(entries: IntersectionObserverEntry[]) {
        this.lazyloadObserverIsIntersecting = entries[0].isIntersecting

        if (this.lazyloadObserverIsIntersecting) {
            // Prevent triggering play while sliding too quickly
            this.lazyloadEnterTimeout = window.setTimeout(() => {
                this.onLazyloadObservableEnter()
            }, 100)
        } else {
            window.clearTimeout(this.lazyloadEnterTimeout)
            this.onLazyloadObservableLeave()
        }
    }

    onLazyloadObservableEnter() {
        // override
    }

    onLazyloadObservableLeave() {
        // override
    }
}
